import React,{useEffect,useState} from 'react';
import Layout from '../../Components/Layout/Layout';
import wow from 'wowjs';
import { ToastContainer,toast } from 'react-toastify';
import emailjs from 'emailjs-com';

const Contact = () => {
    const [formData,setFormData]=useState({email:'',name:'',subject:'',phnno:'',message:''});
    const [isBooking, setIsBooking] = useState(false)
    useEffect(()=>{
        new wow.WOW().init();
    },[]);
    
    const handleChange=(event)=>{
        const {value,name}=event.target;
        setFormData(prevdata=>({
            ...prevdata,
            [name]:value,
        }));
    };

    const isValidEmail=(email)=> {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
      };

    const submitForm = async()=>{
        if(formData.name==='')
        {
            toast.info('Please Enter Your Name.', {
                position: toast.POSITION.TOP_RIGHT
              });
              return;
        } else if(formData.email===''){

            toast.info('Please Enter Your Email.', {
                position: toast.POSITION.TOP_RIGHT
              });
              return;
        }else if(formData.subject==='')
        {
            toast.info('Please Enter Subject.', {
                position: toast.POSITION.TOP_RIGHT
              });
              return;
        }else if(formData.phnno==='')
        {
            toast.info('Please Enter Phone Number.', {
                position: toast.POSITION.TOP_RIGHT
              });
              return;
        }else if(formData.message==='')
        {
            toast.info('Please Enter Message.', {
                position: toast.POSITION.TOP_RIGHT
              });
              return;
        }
        else if(!isValidEmail(formData.email))
        {
            toast.info('Please Enter a valid email.', {
                position: toast.POSITION.TOP_RIGHT
              });
              return;
        }
        setIsBooking(true)
        await sendUserMessage();
        // await sendOwnerMessage();
        setIsBooking(false)
        toast.success('Dear Customer,Thank you for contacting Sidra Tours.One of our agent will contact you within 12 to 24 hours.')
        // try {
        //     const templateParams = {
        //       email: formData.email,
        //       name: formData.name,
        //       subject: formData.subject, 
        //       phone : formData.phnno,
        //       message: formData.message
        //     };
      
        //     await emailjs.send('service_ebaotht', 'template_k4x9mo5', templateParams, '1f_kV-TzsfRm-ooUO');
            
        //     toast.success('Email Send Successfully.', {
        //         position: toast.POSITION.TOP_RIGHT
        //       });
        //   } catch (error) {
        //     console.error('Error sending email', error);
        //   }
    }
    const sendUserMessage = async()=> {
const messages = [
    {
        recipient: '+44123456789',
        message: `Dear Sidra Tours,
      
  A customer has contact via contact form.
      
  Name:${formData.name}
  Email:${formData.email}
  Number:${formData.phnno}
  Subject:${formData.subject}
  Message:${formData.message}
      
  Thank You`
      },
    {
      recipient:formData.phnno ,
      message: `Dear Customer,\nThank you for contacting Sidra Tours.\nOne of our agent will contact you within 12 to 24 hours.
    
Thank You 
Team Sidra Tours`
    }
    
  ];
  const apiKey = 'RUU6Wkzm2JRt';
    for (let i = 0; i < messages.length; i++) {
        const { recipient, message } = messages[i];
        const encodedMessage = encodeURIComponent(message);
        const url = `http://api.textmebot.com/send.php?recipient=${recipient}&apikey=${apiKey}&text=${encodedMessage}`;
    
        try {
          const response = await fetch(url);
          
          if (response.ok) {
            const contentType = response.headers.get('content-type');
            
            if (contentType && contentType.includes('application/json')) {
              const data = await response.json();
              console.log(`Message sent to ${recipient}:`, data);
            } else {
              const text = await response.text();
              console.log(`Message sent to ${recipient}:`, text);
            }
    
            // Wait 7 seconds before sending the next message
            if (i < messages.length - 1) {
              await new Promise(resolve => setTimeout(resolve, 7000));
            }
    
          } else {
            console.error(`Error sending message to ${recipient}:`, response.statusText);
          }
        } catch (error) {
            if (i < messages.length - 1) {
                await new Promise(resolve => setTimeout(resolve, 7000));
              }
          console.error(`Error sending message to ${recipient}:`, error);
        }
      }
  }
    return (
        <>
        <Layout>
            <ToastContainer/>
            <div className="col-12 mt-2 mb-5">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-md-12">
                            <div className="wrapper">
                               
                                <div className="row no-gutters">
                                    <div className="col-lg-8 col-md-7 order-md-last d-flex align-items-stretch wow animate__animated animate__fadeInRight" data-wow-duration="1s" data-wow-delay="0.2s">
                                        <div className="contact-wrap w-100  p-4">
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <div className="form-group p-3">
                                                            <label className="label_text">You Name</label>
                                                            <input type="text" placeholder='Name' onChange={handleChange} className="form-control" name="name" required />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group p-3">
                                                            <label className="label_text">Your Email</label>
                                                            <input type="email" placeholder='Email' onChange={handleChange}  className="form-control" name="email"  required />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group p-3">
                                                            <label className="label_text">Your Subject</label>
                                                            <input type="text" placeholder='Subject' onChange={handleChange}  className="form-control" name="subject"  required />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group p-3">
                                                            <label className="label_text">Phone Number</label>
                                                            <input type="text" placeholder='Number' onChange={handleChange} className="form-control" name="phnno"  required />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12">
                                                        <div className="form-group p-3">
                                                            <label className="label_text">Message</label>
                                                            <textarea name="message" placeholder='Message'  className="form-control" onChange={handleChange} cols="30" rows="4" required></textarea>
                                                            <div className="mb-2">
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                    <div className="form-group p-3">
                                                                <button type="submit" onClick={submitForm} disabled={isBooking}  className="btn btn-primary select-styling search-btn1">{isBooking ? 'Please Wait...' : 'Submit'}</button>
                                                            </div>
                                                    </div>
                                                
                                                </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-5 d-flex align-items-stretch wow animate__animated animate__fadeInLeft" data-wow-duration="1s" data-wow-delay="0.2s">
                                        <div className="info-wrap contact-us-bg w-100  p-4">
                                            <div className="mapviewport">
                                                <div id="mapgyver_holder"  >
                                                    <h4 className="h4-contact text-center mt-3"><span style={{ color: '#ffb606', fontWeight: 'bold' }} >Sidra Tours</span></h4>
                                                    <div className="content-behind-map Contact_Us_Right_Div">
                                                        <h4 className="h4-contact h4-contact-gap"> Location</h4>
                                                        <p className="p-contact"> <strong className="strong_text"> Address:</strong> <b class="fa fa-map-marker" aria-hidden="true"> </b>40 Oxford St, Batley, West Yorkshire England, WF177PR<br /> <br />  </p>
                                                          <h4 className="h4-contact h4-contact-gap">Call Us:</h4>
                                                            
                                                        <p className="p-contact">  <strong className="strong_text"> Phone:</strong> <b className="fa fa-phone" aria-hidden="true"> </b>+44 7305 162186</p>
                                                        <h4 className="h4-contact  h4-contact-gap"> Email</h4>
                                                        <p className="p-contact mail" style={{ color: 'white' }}>sidratours@hotmail.com</p>
                                                        <p className="p-contact mail" style={{ color: 'white' }}>info@sidratours.com</p>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
           
            </Layout>
        </>
    );

}
export default Contact;