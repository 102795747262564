export function Hotelapitoken(){
    return 'jdpCfdBIo5sek9BHzOlKIxKVDKyIVwy2WwzIuTlx-W1KTKA0GQhO9OpcV6Lw3DOUKWesNOP2uo0hjwkQ1yUdRps54jZHma7Ru0rti-h255ezpacThjEqQZ2I2dKJXnL-meNI1NqcUoUlX2ODlOtlVF5MxKQ5KEFvNlyoQfBRDxzWb7k5YLNmIML32JmldZ48QOMy6tVmA2Q-MwhB5dSYu6OYpppqIMniOFI42w6UgW6n8LyupWW5QYrILECN65E3uWCLCuFT6ZmficWQy6lqCWN6LGW9utXbwB2LdezbrrMv9TgB-58STN11HZKAvhAFSExauRs4McxihUJ9Xaa9uGivElL1Mw3bZvr2Gy0o6GK84uz57ozLuaw8nI7L-IXMY7NKeQfcqYjggDhrO3YzVa-PDD84dnFkpzRcdQwyn5zm19FubWZ60iyNCBmI8mAzRslpsgEeX-aAQfbjfzBkqDY2MXaQp8JZxb9YYb5N2xvaa92vklAWnYdmSBb2'
};

export function CustomerDomainName(){
    return 'https://system.alhijaztours.net/'
};
export function FlightSearchToken(){
    return 'jdpCfdBIo5sek9BHzOlKIxKVDKyIVwy2WwzIuTlx-W1KTKA0GQhO9OpcV6Lw3DOUKWesNOP2uo0hjwkQ1yUdRps54jZHma7Ru0rti-h255ezpacThjEqQZ2I2dKJXnL-meNI1NqcUoUlX2ODlOtlVF5MxKQ5KEFvNlyoQfBRDxzWb7k5YLNmIML32JmldZ48QOMy6tVmA2Q-MwhB5dSYu6OYpppqIMniOFI42w6UgW6n8LyupWW5QYrILECN65E3uWCLCuFT6ZmficWQy6lqCWN6LGW9utXbwB2LdezbrrMv9TgB-58STN11HZKAvhAFSExauRs4McxihUJ9Xaa9uGivElL1Mw3bZvr2Gy0o6GK84uz57ozLuaw8nI7L-IXMY7NKeQfcqYjggDhrO3YzVa-PDD84dnFkpzRcdQwyn5zm19FubWZ60iyNCBmI8mAzRslpsgEeX-aAQfbjfzBkqDY2MXaQp8JZxb9YYb5N2xvaa92vklAWnYdmSBb2'
};

export function ApiEndPoint(){
    return 'https://api.synchtravel.com'
};
export function TransferApiToken(){
    return 'jdpCfdBIo5sek9BHzOlKIxKVDKyIVwy2WwzIuTlx-W1KTKA0GQhO9OpcV6Lw3DOUKWesNOP2uo0hjwkQ1yUdRps54jZHma7Ru0rti-h255ezpacThjEqQZ2I2dKJXnL-meNI1NqcUoUlX2ODlOtlVF5MxKQ5KEFvNlyoQfBRDxzWb7k5YLNmIML32JmldZ48QOMy6tVmA2Q-MwhB5dSYu6OYpppqIMniOFI42w6UgW6n8LyupWW5QYrILECN65E3uWCLCuFT6ZmficWQy6lqCWN6LGW9utXbwB2LdezbrrMv9TgB-58STN11HZKAvhAFSExauRs4McxihUJ9Xaa9uGivElL1Mw3bZvr2Gy0o6GK84uz57ozLuaw8nI7L-IXMY7NKeQfcqYjggDhrO3YzVa-PDD84dnFkpzRcdQwyn5zm19FubWZ60iyNCBmI8mAzRslpsgEeX-aAQfbjfzBkqDY2MXaQp8JZxb9YYb5N2xvaa92vklAWnYdmSBb2'
    // return 'jdpCfdBIo5sek9BHzOlKIxKVDKyIVwy2WwzIuTlx-W1KTKA0GQhO9OpcV6Lw3DOUKWesNOP2uo0hjwkQ1yUdRps54jZHma7Ru0rti-h255ezpacThjEqQZ2I2dKJXnL-meNI1NqcUoUlX2ODlOtlVF5MxKQ5KEFvNlyoQfBRDxzWb7k5YLNmIML32JmldZ48QOMy6tVmA2Q-MwhB5dSYu6OYpppqIMniOFI42w6UgW6n8LyupWW5QYrILECN65E3uWCLCuFT6ZmficWQy6lqCWN6LGW9utXbwB2LdezbrrMv9TgB-58STN11HZKAvhAFSExauRs4McxihUJ9Xaa9uGivElL1Mw3bZvr2Gy0o6GK84uz57ozLuaw8nI7L-IXMY7NKeQfcqYjggDhrO3YzVa-PDD84dnFkpzRcdQwyn5zm19FubWZ60iyNCBmI8mAzRslpsgEeX-aAQfbjfzBkqDY2MXaQp8JZxb9YYb5N2xvaa92vklAWnYdmSBb2'

};
export function TransferCheckoutToken(){
    return 'jdpCfdBIo5sek9BHzOlKIxKVDKyIVwy2WwzIuTlx-W1KTKA0GQhO9OpcV6Lw3DOUKWesNOP2uo0hjwkQ1yUdRps54jZHma7Ru0rti-h255ezpacThjEqQZ2I2dKJXnL-meNI1NqcUoUlX2ODlOtlVF5MxKQ5KEFvNlyoQfBRDxzWb7k5YLNmIML32JmldZ48QOMy6tVmA2Q-MwhB5dSYu6OYpppqIMniOFI42w6UgW6n8LyupWW5QYrILECN65E3uWCLCuFT6ZmficWQy6lqCWN6LGW9utXbwB2LdezbrrMv9TgB-58STN11HZKAvhAFSExauRs4McxihUJ9Xaa9uGivElL1Mw3bZvr2Gy0o6GK84uz57ozLuaw8nI7L-IXMY7NKeQfcqYjggDhrO3YzVa-PDD84dnFkpzRcdQwyn5zm19FubWZ60iyNCBmI8mAzRslpsgEeX-aAQfbjfzBkqDY2MXaQp8JZxb9YYb5N2xvaa92vklAWnYdmSBb2'
};

export function CurrencyConverter(){
    return 'b8d4b285d48d1e690388e999'
};
export function StripeClientSecret(){
    // return 'sk_test_51N9pi8GwYlRApJ0wzqUOVIJBpCNJ1Lz7UcUjJoSdMU73WAVzoTXSJ1gfakCYThPWhnzWlKnLLQXugdB0s8dBFDJ500Beg22CmF'
    return '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'
};
export function ActivityToken(){
    return 'jdpCfdBIo5sek9BHzOlKIxKVDKyIVwy2WwzIuTlx-W1KTKA0GQhO9OpcV6Lw3DOUKWesNOP2uo0hjwkQ1yUdRps54jZHma7Ru0rti-h255ezpacThjEqQZ2I2dKJXnL-meNI1NqcUoUlX2ODlOtlVF5MxKQ5KEFvNlyoQfBRDxzWb7k5YLNmIML32JmldZ48QOMy6tVmA2Q-MwhB5dSYu6OYpppqIMniOFI42w6UgW6n8LyupWW5QYrILECN65E3uWCLCuFT6ZmficWQy6lqCWN6LGW9utXbwB2LdezbrrMv9TgB-58STN11HZKAvhAFSExauRs4McxihUJ9Xaa9uGivElL1Mw3bZvr2Gy0o6GK84uz57ozLuaw8nI7L-IXMY7NKeQfcqYjggDhrO3YzVa-PDD84dnFkpzRcdQwyn5zm19FubWZ60iyNCBmI8mAzRslpsgEeX-aAQfbjfzBkqDY2MXaQp8JZxb9YYb5N2xvaa92vklAWnYdmSBb2'
};
export function HajjApiToken(){
    return 'jdpCfdBIo5sek9BHzOlKIxKVDKyIVwy2WwzIuTlx-W1KTKA0GQhO9OpcV6Lw3DOUKWesNOP2uo0hjwkQ1yUdRps54jZHma7Ru0rti-h255ezpacThjEqQZ2I2dKJXnL-meNI1NqcUoUlX2ODlOtlVF5MxKQ5KEFvNlyoQfBRDxzWb7k5YLNmIML32JmldZ48QOMy6tVmA2Q-MwhB5dSYu6OYpppqIMniOFI42w6UgW6n8LyupWW5QYrILECN65E3uWCLCuFT6ZmficWQy6lqCWN6LGW9utXbwB2LdezbrrMv9TgB-58STN11HZKAvhAFSExauRs4McxihUJ9Xaa9uGivElL1Mw3bZvr2Gy0o6GK84uz57ozLuaw8nI7L-IXMY7NKeQfcqYjggDhrO3YzVa-PDD84dnFkpzRcdQwyn5zm19FubWZ60iyNCBmI8mAzRslpsgEeX-aAQfbjfzBkqDY2MXaQp8JZxb9YYb5N2xvaa92vklAWnYdmSBb2'
};


// 88eb0b8be5318bc998d94933
// b8d4b285d48d1e690388e999 new 30000