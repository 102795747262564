import React from "react";
import { NavLink } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight ,faLocationArrow} from '@fortawesome/free-solid-svg-icons'
import fb from '../../Images/Logo/fb_logo.png'
import insta from '../../Images/Logo/insta.png'
import linkedin from '../../Images/Logo/linkedin_logo.png'
import twitter from '../../Images/Logo/twitter_logo.png'
import logo from './logo-fff.png'
import moment from "moment";
function Footer(){
    return(
    <>
        <footer className="footer-section1 mt-5">
          <div className="footer-top">
            <div className="container">
              <div className="row">
                <div className="col-lg-4 col-md-4 col-sm-6">
                  <div className="widget text-justify company-intro-widget">
                  <div className="widget course-links-widget">
                    <h5 className="widget-title">Helpfull Links</h5>
                    <ul className="courses-link-list">
                      <li><NavLink className='nav-link' to='/terms_and_conditions'><i className="fas fa-long-arrow-alt-right"><FontAwesomeIcon icon={faArrowRight}/></i>Terms & conditions</NavLink></li>
                      <li><NavLink className='nav-link' to='/complaint_policy'><i className="fas fa-long-arrow-alt-right"><FontAwesomeIcon icon={faArrowRight}/></i>Complaints Policy</NavLink></li>
                      <li><NavLink className='nav-link' to='/privacy_policy'><i className="fas fa-long-arrow-alt-right"><FontAwesomeIcon icon={faArrowRight}/></i>Privacy Policy</NavLink></li>
                      <li><NavLink className='nav-link' to='/faqs'><i className="fas fa-long-arrow-alt-right"><FontAwesomeIcon icon={faArrowRight}/></i>FAQs</NavLink></li>
                      <li><NavLink className='nav-link' to='/about-us'><i className="fas fa-long-arrow-alt-right"><FontAwesomeIcon icon={faArrowRight}/></i>About Us</NavLink></li>
                      <li><NavLink className='nav-link' to='/contact-us'><i className="fas fa-long-arrow-alt-right"><FontAwesomeIcon icon={faArrowRight}/></i>Contact Us</NavLink></li>
                    </ul>
                  </div>
                  </div>
                </div>
                {/* <div className="col-lg-3 col-md-3 col-sm-6">
                  <div className="widget course-links-widget">
                    <h5 className="widget-title">Helpfull Links</h5>
                    <ul className="courses-link-list">
                      <li><NavLink className='nav-link' to='/terms_and_conditions'><i className="fas fa-long-arrow-alt-right"><FontAwesomeIcon icon={faArrowRight}/></i>Terms & conditions</NavLink></li>
                      <li><NavLink className='nav-link' to='/complaint_policy'><i className="fas fa-long-arrow-alt-right"><FontAwesomeIcon icon={faArrowRight}/></i>Complaints Policy</NavLink></li>
                      <li><NavLink className='nav-link' to='/privacy_policy'><i className="fas fa-long-arrow-alt-right"><FontAwesomeIcon icon={faArrowRight}/></i>Privacy Policy</NavLink></li>
                      <li><NavLink className='nav-link' to='/faqs'><i className="fas fa-long-arrow-alt-right"><FontAwesomeIcon icon={faArrowRight}/></i>FAQs</NavLink></li>
                      <li><NavLink className='nav-link' to='/about-us'><i className="fas fa-long-arrow-alt-right"><FontAwesomeIcon icon={faArrowRight}/></i>About Us</NavLink></li>
                      <li><NavLink className='nav-link' to='/contact-us'><i className="fas fa-long-arrow-alt-right"><FontAwesomeIcon icon={faArrowRight}/></i>Contact Us</NavLink></li>
                    </ul>
                  </div>
                </div> */}
                
                <div className="col-md-4">
                <div className="widget course-links-widget">
                    <h5 className="widget-title">Become an Affiliate</h5>
                    <ul className="courses-link-list">
                        
                   
                      <li><p className='nav-link' ><i className="fas fa-long-arrow-alt-right"><FontAwesomeIcon icon={faArrowRight}/></i>Car hire</p></li>
                      <li><p className='nav-link' ><i className="fas fa-long-arrow-alt-right"><FontAwesomeIcon icon={faArrowRight}/></i>Flight finder</p></li>
                      <li><p className='nav-link' ><i className="fas fa-long-arrow-alt-right"><FontAwesomeIcon icon={faArrowRight}/></i> Hotels reservations</p></li>

                    </ul>
                  </div>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6">
                  <div className="widget newsletter-widget">
                    <h5 className="widget-title">For Special booking request, please call</h5>
                    <div className="footer-newsletter">
                      <h3>+44 7305 162186</h3>
                      <p><FontAwesomeIcon icon={faLocationArrow}/> 40 Oxford St, Batley, West Yorkshire England, WF177PR</p>
                      <div className="social-icons mt-4">
                        <a className="logo_hover" href="#" target="_blank"> <img src={fb} alt="Facebook Logo" width="47px"/></a>
                        <a className="logo_hover ms-4" href="#" target="_blank"> <img src={insta} alt="Insta Logo" height="55px" width="55px"/></a>
                        <a className="logo_hover ms-4" href="#" target="_blank"> <img src={linkedin} alt="LinkedIn Logo" height="50px" width="50px"/></a>
                        <a className="logo_hover ms-4" href="#" target="_blank"> <img src={twitter} alt="Facebook Logo" width="54px"/></a></div>
                      

                    </div>
                  </div>
                </div>
              </div>
            </div> 
          </div>
          <div className="footer-bottom">
            <div className="container">
              <div className="row">
                  <div className="col-md-12 col-sm-12 text-sm-left text-center">
                    <span className="copy-right-text">© {moment().format('YYYY')}<span className="web-color"> Sidra Tours</span> All Rights Reserved.</span>
                  </div>
              </div>
            </div>
          </div>
        </footer>
    </>        
    );
}

export default Footer;