import React,{useState,useEffect} from "react";
import bgimage from '../../Images/Hajj/1.jpg'
import kabahpic from '../../Images/Hajj/3.jpeg'
import logo from '../../Images/Logo/logo.png'
import Axios from "axios";
import Select from 'react-select';
import Loading from "../../Components/Loading/Loader";
import { ToastContainer, toast } from 'react-toastify';
import Layout from "../../Components/Layout/Layout";
import { useNavigate } from "react-router-dom";
import { HajjApiToken,ApiEndPoint } from "../../Components/GlobalData/GlobalData";
function HajjDetail(){
    const navigation=useNavigate();
    const [formData,setFormData]=useState({email:'',fname:'',lname:'',passenger:'',phnno:'',address:'',city:'',postode:''});
    const [countryList, setCountryList] = useState([]);
    const [selectedCountry, setSelectedCountry] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    const handleCountryChange = (selectedOption) => {
      setSelectedCountry(selectedOption);
      setFormData(prevdata=>({
        ...prevdata,
        phnno:selectedOption.phoneCode,
      }));
    };

    const handleChange=(event)=>{
        const {value,name}=event.target;
        setFormData(prevdata=>({
            ...prevdata,
            [name]:value,
        }));
    };

    useEffect(() => {
        const fetchData = async () => {
          
          await new Promise(resolve => setTimeout(resolve, 2000))
          setIsLoading(false)
        }
    
        fetchData()
      }, []);

    const isValidEmail=(email)=> {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
      };

    const submitFormData=async()=>{
        if(formData.email===''){

            toast.info('Please Enter Your Email.', {
                position: toast.POSITION.TOP_RIGHT
              });
              return;
        }else if(formData.fname==='')
        {
            toast.info('Please Enter Your First Name.', {
                position: toast.POSITION.TOP_RIGHT
              });
              return;
        }else if(formData.lname==='')
        {
            toast.info('Please Enter Your Last Name.', {
                position: toast.POSITION.TOP_RIGHT
              });
              return;
        }else if(selectedCountry===null)
        {
            toast.info('Please Select Country.', {
                position: toast.POSITION.TOP_RIGHT
              });
              return;
        }else if(formData.passenger==='')
        {
            toast.info('Please Enter Passenger.', {
                position: toast.POSITION.TOP_RIGHT
              });
              return;
        }else if(formData.phnno==='')
        {
            toast.info('Please Enter Phone Number.', {
                position: toast.POSITION.TOP_RIGHT
              });
              return;
        }else if(formData.city==='')
        {
            toast.info('Please Enter City.', {
                position: toast.POSITION.TOP_RIGHT
              });
              return;
        }
        else if(!isValidEmail(formData.email))
        {
            toast.info('Please Enter a valid email.', {
                position: toast.POSITION.TOP_RIGHT
              });
              return;
        }
        const endpoint=ApiEndPoint();
        const token=HajjApiToken();
        setIsLoading(true);
        var data={
                'token':token,
                'email_Address':formData.email,
                'first_Name':formData.fname,
                'last_Name':formData.lname,
                'no_Of_Passengers':formData.passenger,
                'phone_No':formData.phnno,
                'street_Address':formData.address,
                'city':formData.city,
                'post_Code':formData.postode,
                'country':selectedCountry.value,
        };
        try{

            const response=await Axios.post(endpoint+'/api/create_ShowInterest',data,{
                headers: {
                    "Access-Control-Allow-Origin": "*",
                  } ,
            })
            setIsLoading(false);
            if(response.data.status==='success' ){
                navigation('/hajj-confirmation');
                // toast.success('Thank you for registering your interest in Hajj 2024 with Al Hijaz Tours Ltd.', {
                //     position: toast.POSITION.TOP_RIGHT
                //   });
            }else if(response.data.status==='error'){
                toast.error(response.data.message, {
                    position: toast.POSITION.TOP_RIGHT
                  });
            }
        } catch(error){
            setIsLoading(false);
            console.log('error',error);
        }

    };

    useEffect(() => {
        fetchData();
      }, []);
    
      async function fetchData () {
        Axios.get('https://restcountries.com/v3.1/all?fields=name,flags,cca2,cca3,idd')
          .then(response => {
            const countryOptions = response?.data.map((country) => ({
                value:country.name.common,
                label: country.name.common,
                flag: country.flags.png,
                phoneCode: country.idd.root+country.idd.suffixes[0],
              }));
            setCountryList(countryOptions)
          })
          .catch(error => {
            console.error('Error:', error)
          })
      };
    return(
        <>
        {isLoading &&(
            <Loading/>
        )}
        <ToastContainer/>
        <Layout>
            <div>
                <img src={bgimage}  />
            </div>
            <div className="container mt-3">
                    <h3>Hajj 2024 – Register Your Interest</h3>
                    <h6>Hajj 2024</h6>
                    <div className="row mt-4">
                        <div className="col-md-7">
                            <h5>Update for Hajj 2024/1445H</h5>
                            <p className="mt-3">We are pleased to announce that Al Hijaz Tours  partnered with the highly reputable Saudi company  to successfully facilitate Hajj 1444H/2023 for pilgrims from the UK in line with new guidelines and processes from the Ministry of Hajj and Nusuk Portal.</p>
                            <p className="mt-3">We were extremely honored to provide support and guidance to UK Pilgrims as a Marketing Partner. For 2024, we await details on the Hajj procedure from UK. We expect it to remain under the Nusuk Portal. There is no payment to be made at this stage.</p>
                            <p className="mt-3">Register your interest in Hajj 2024 to stay up to date with package information and the booking process.</p>

                            <div className="row mt-5">
                                <div className="form-group col-md-6 mt-2">
                                    <label>Email address:</label>
                                    <input type="email" onChange={handleChange} value={formData.email} name="email" className="form-control mt-2" placeholder="email address"  />
                                </div>
                                <div className="form-group col-md-6 mt-2">
                                    <label>First Name:</label>
                                    <input type="text" onChange={handleChange} value={formData.fname} name="fname" className="form-control mt-2" placeholder="First Name" />
                                </div>
                                <div className="form-group col-md-6 mt-2 ">
                                    <label>Last Name:</label>
                                    <input type="text" onChange={handleChange} value={formData.lname} name="lname" className="form-control mt-2" placeholder="Last Name" />
                                </div>
                                <div className="form-group col-md-6 mt-2">
                                    <label>City:</label>
                                    <input type="text" onChange={handleChange} value={formData.city} name="city" className="form-control mt-2" placeholder="City" />
                                </div>
                                <div className="form-group col-md-6 mt-2">
                                    <label>Country:</label>
                                    <Select
                                        options={countryList}
                                        isSearchable={true}
                                        className="mt-2"
                                        onChange={handleCountryChange}
                                        value={selectedCountry}
                                        getOptionLabel={(option) => (
                                            <div>
                                            <img
                                                src={option.flag}
                                                alt={option.label}
                                                style={{ width: '20px', marginRight: '8px' }}
                                            />
                                            {option.label} ({option.phoneCode})
                                            </div>
                                        )}
                                        getOptionValue={(option) => option.value}
                                        />
                                </div>
                                <div className="form-group col-md-6 mt-2">
                                    <label>Phone Number:</label>
                                    <input type="text" onChange={handleChange} value={formData.phnno} name="phnno" className="form-control mt-2" placeholder="Number" />
                                </div>
                                <div className="form-group col-md-6 mt-2">
                                    <label>Number Of Passengers:</label>
                                    <input type="number" onChange={handleChange} value={formData.passenger} name="passenger" className="form-control mt-2" placeholder="Passengers" />
                                </div>
                                <div className="form-group col-md-6 mt-2">
                                    <label>Street Address:</label>
                                    <input type="text" onChange={handleChange} value={formData.address} name="address" className="form-control mt-2" placeholder="Street Address" />
                                </div>
                                <div className="form-group col-md-6 mt-2">
                                    <label>Post Code:</label>
                                    <input type="text" onChange={handleChange} value={formData.postode} name="postode" className="form-control mt-2" placeholder="Post Code" />
                                </div>
                                
                            </div>
                            <div className="row mt-2">
                            <div className="form-group col-md-5 mt-2">
                            <button onClick={submitFormData} class="btn btn-primary btn-block select-styling search-btn1">Register Your Interest</button>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-5">
                            <div className="p-3">
                                <img src={kabahpic} />
                                <div className="hajj-img">
                                    <p>Leading Hajj & Umrah Provider</p>
                                    <img src={logo}/>
                                </div>
                            </div>
                        </div>
                    </div>
            </div>
        </Layout>
        </>
    );
}

export default HajjDetail;